import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from "./router"
import VueCookies from 'vue3-cookies';
import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/fontawesome/css/all.css";
import "bootstrap";
import 'vue3-carousel/dist/carousel.css';
import './registerServiceWorker'
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import jQuery from "jquery";
import { Dropdown } from 'bootstrap';

router.beforeEach((to, from, next) => {
    const isLoggedIn = store.getters.isAuthenticated;
    const siteSettings = store.getters.siteSettings;
    const underMaintenance = store.getters.underMaintenance;
    const isMaintenancePage = to.name === 'Maintenance';

    if (underMaintenance) {
        if (!isMaintenancePage) {
            next({ name: 'Maintenance' });
        } else {
            next();
        }
    } else {
        if (window.store?.getters.isAuthenticated && !window.store?.getters?.stateUser?.last_login_at && to.name != 'change-password') {
            window.router.push({ name: 'change-password' });
        } else if (siteSettings && siteSettings.business_type == 1) {
            if (to.path === '/login' || to.path === '/forgot-password' || to.path === '/register') {
                if (isLoggedIn) {
                    next('/');
                } else {
                    next();
                }
            } else {
                if (isLoggedIn) {
                    next();
                } else {
                    next('/login');
                }
            }
        } else if (siteSettings && siteSettings.business_type == 2) {
            if (to.path === '/login' || to.path === '/forgot-password' || to.path === '/register') {
                if (isLoggedIn) {
                    next('/');
                } else {
                    next();
                }
            } else {
                next();
            }
        } else {
            next();
        }
    }
});

window.addEventListener('popstate', (event) => {
    if(document.querySelector('.modal.show')) {
        window.location.reload();
    }
});

router.beforeEach((to, from, next) => {
    if(document.querySelector('.modal.show')) {
        next(false);
    } else {
        next();
    }
    const dropdownMenu = document.querySelector('.dropdown-account .dropdown-menu.show');
    if (dropdownMenu) {
        const headAccElement = document.querySelector('.dropdown-account .dropdown-toggle');
        const headAcc = Dropdown.getInstance(headAccElement);
        if (headAcc) {
            headAcc.hide();
        }
    }
});
const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueCookies);
app.mount("#app");

window.axios = require('axios');

// define global properties
app.config.globalProperties.fantasy_server = "fantasycricket";
app.config.globalProperties.click_server = "fantasy";