<template>
    <div class="col-md-2" v-if="$route.name == 'Sports' && $route.params.type == 'football'">
        <div class="sidebar">
            <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button sidebar-title custom-tab-cmn-btn" data-bs-toggle="collapse"
                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <span>Others</span>
                        </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <nav class="collapse casino show">
                                <ul>
                                    <li class="nav-item">
                                        <a href="Tembo-Casino.html" class="nav-link"><span class="new-launch-text">Tembo
                                                Casino</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="livecasino.html" class="nav-link"><span>Live Casino</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="slotgame.html" class="nav-link"><span>Slot Game</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="race-t20.html" class="nav-link"><span>Race 20-20</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="queen.html" class="nav-link"><span>Casino Queen</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="Dragon-tiger.html" class="nav-link"><span>Dragon Tiger</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="sportscasino.html" class="nav-link"><span>Sports Casino</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="Andar-Bahar.html" class="nav-link"><span>Andar Bahar</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="bollywood-casino.html" class="nav-link"><span>Bollywood Casino</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="casino-war.html" class="nav-link"><span>Casino War</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="worli.html" class="nav-link"><span>Worli</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="lottery.html" class="nav-link"><span>Lottery</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="Judgement.html" class="nav-link"><span>3 Cards Judgement</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="binary.html" class="nav-link"><span>Binary</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="virtualsports.html" class="nav-link"><span>Virtual Sports</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="cricket-casino.html" class="nav-link"><span>Cricket Casino</span></a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <div class="accordion-item" id="coll-list">
                    <h2 class="accordion-header" id="headingtwo">
                        <button class="accordion-button sidebar-title custom-tab-cmn-btn" data-bs-toggle="collapse"
                            data-bs-target="#collapsetwo" aria-expanded="true" aria-controls="collapsetwo">
                            <span>All Sports</span>
                        </button>
                    </h2>
                    <div id="collapsetwo" class="accordion-collapse collapse show" aria-labelledby="headingtwo"
                        data-bs-parent="#coll-list">
                        <div class="accordion-body">
                            <aside class="bd-sidebar">
                                <div class="left-side-menu bd-links" id="sidebar-menu">
                                    <ul class="scroll-manu-side" id="side-menu">
                                        <li class="cum-list-game">
                                            <a data-bs-toggle="collapse" href="#collapse1"
                                                class="collapsed custom-tab-cmn-btn custom-tab-cmn-btn"
                                                aria-expanded="false">
                                                <span class="main-heading">Football </span><span class="menu-arrow"><i
                                                        class="fa-solid fa-plus"></i></span>
                                            </a>
                                            <div class="collapse" id="collapse1">
                                                <ul class="nav-second-level">
                                                    <li>
                                                        <a class="custom-tab-cmn-btn" data-bs-toggle="collapse"
                                                            href="#collapse44">
                                                            ATP - SINGLES TORONTO (CANADA)<span class="menu-arrow"><i
                                                                    class="fa-solid fa-plus"></i></span>
                                                        </a>
                                                        <div class="collapse" id="collapse44">
                                                            <ul class="nav-second-level">
                                                                <li>
                                                                    <a class="custom-tab-cmn-btn" data-bs-toggle="collapse"
                                                                        href="#collapse55">
                                                                        2023/06/07 <span class="menu-arrow"><i
                                                                                class="fa-solid fa-plus"></i></span>
                                                                    </a>
                                                                    <div class="collapse" id="collapse55">
                                                                        <ul class="nav-second-level">
                                                                            <li>
                                                                                <a href="football-detail-list(1).html"
                                                                                    class="caret-right-play">
                                                                                    <span><i
                                                                                            class="fas fa-caret-right"></i></span>
                                                                                    Sonego v Murray
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="football-detail-list(2).html"
                                                                                    class="caret-right-play">
                                                                                    <span><i
                                                                                            class="fas fa-caret-right"></i></span>Vukic
                                                                                    v Coric
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li class="cum-list-game">
                                            <a class="custom-tab-cmn-btn" data-bs-toggle="collapse" href="#collapse3">
                                                <span class="main-heading"> Tennis </span><span class="menu-arrow"><i
                                                        class="fa-solid fa-plus"></i></span>
                                            </a>
                                            <div class="collapse" id="collapse3">
                                                <ul class="nav-second-level">
                                                    <li>
                                                        <a class="custom-tab-cmn-btn" data-bs-toggle="collapse"
                                                            href="#collapse4">
                                                            ATP - SINGLES TORONTO (CANADA)<span class="menu-arrow"><i
                                                                    class="fa-solid fa-plus"></i></span>
                                                        </a>
                                                        <div class="collapse" id="collapse4">
                                                            <ul class="nav-second-level">
                                                                <li>
                                                                    <a class="custom-tab-cmn-btn" data-bs-toggle="collapse"
                                                                        href="#collapse5">
                                                                        2023/06/07 <span class="menu-arrow"><i
                                                                                class="fa-solid fa-plus"></i></span>
                                                                    </a>
                                                                    <div class="collapse" id="collapse5">
                                                                        <ul class="nav-second-level">
                                                                            <li>
                                                                                <a href="Tennis-detail-list(1).html"
                                                                                    class="caret-right-play">
                                                                                    <span><i
                                                                                            class="fas fa-caret-right"></i></span>
                                                                                    Sonego v Murray
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="Tennis-detail-list(2).html"
                                                                                    class="caret-right-play">
                                                                                    <span><i
                                                                                            class="fas fa-caret-right"></i></span>Vukic
                                                                                    v Coric
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li class="cum-list-game">
                                            <a class="custom-tab-cmn-btn" data-bs-toggle="collapse" href="#collapse8">
                                                <span class="main-heading">Cricket</span><span class="menu-arrow"><i
                                                        class="fa-solid fa-plus"></i></span>
                                            </a>
                                            <div class="collapse" id="collapse8">
                                                <ul class="nav-second-level">
                                                    <li>
                                                        <a class="custom-tab-cmn-btn" data-bs-toggle="collapse"
                                                            href="#collapse9">
                                                            Ashes Common Market 2023 <span class="menu-arrow"><i
                                                                    class="fa-solid fa-plus"></i></span>
                                                        </a>
                                                        <div class="collapse" id="collapse9">
                                                            <ul class="nav-second-level">
                                                                <li>
                                                                    <a class="custom-tab-cmn-btn" data-bs-toggle="collapse"
                                                                        href="#collapse10">
                                                                        2023/06/07 <span class="menu-arrow"><i
                                                                                class="fa-solid fa-plus"></i></span>
                                                                    </a>
                                                                    <div class="collapse" id="collapse10">
                                                                        <ul class="nav-second-level">
                                                                            <li>
                                                                                <a href="cricket-list-other(1).html"
                                                                                    class="caret-right-play">
                                                                                    <span><i
                                                                                            class="fas fa-caret-right"></i></span>
                                                                                    Ashes Common Market 2023
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <a class="custom-tab-cmn-btn" data-bs-toggle="collapse"
                                                            href="#collapse18">
                                                            County Championship Division 1 <span class="menu-arrow"><i
                                                                    class="fa-solid fa-plus"></i></span>
                                                        </a>
                                                        <div class="collapse" id="collapse18">
                                                            <ul class="nav-second-level">
                                                                <li>
                                                                    <a class="custom-tab-cmn-btn" data-bs-toggle="collapse"
                                                                        href="#collapse19">
                                                                        2023/06/07 <span class="menu-arrow"><i
                                                                                class="fa-solid fa-plus"></i></span>
                                                                    </a>
                                                                    <div class="collapse" id="collapse19">
                                                                        <ul class="nav-second-level">
                                                                            <li>
                                                                                <a href="cricket-list-other(2).html"
                                                                                    class="caret-right-play">
                                                                                    <span><i
                                                                                            class="fas fa-caret-right"></i></span>
                                                                                    Surrey v Nottinghamshire
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="cricket-list-other(2).html"
                                                                                    class="caret-right-play">
                                                                                    <span><i
                                                                                            class="fas fa-caret-right"></i></span>
                                                                                    Middlesex v Northamptonshire
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <a class="custom-tab-cmn-btn" data-bs-toggle="collapse"
                                                            href="#collapse36">
                                                            Test Matches <span class="menu-arrow"><i
                                                                    class="fa-solid fa-plus"></i></span>
                                                        </a>
                                                        <div class="collapse" id="collapse36">
                                                            <ul class="nav-second-level">
                                                                <li>
                                                                    <a class="custom-tab-cmn-btn" data-bs-toggle="collapse"
                                                                        href="#collapse37">
                                                                        2023/06/07 <span class="menu-arrow"><i
                                                                                class="fa-solid fa-plus"></i></span>
                                                                    </a>
                                                                    <div class="collapse" id="collapse37">
                                                                        <ul class="nav-second-level">
                                                                            <li>
                                                                                <a href="cricket-list-other(3).html"
                                                                                    class="caret-right-play">
                                                                                    <span><i
                                                                                            class="fas fa-caret-right"></i></span>
                                                                                    West Indies v India
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <a class="custom-tab-cmn-btn" data-bs-toggle="collapse"
                                                            href="#collapse38">
                                                            Testing <span class="menu-arrow"><i
                                                                    class="fa-solid fa-plus"></i></span>
                                                        </a>
                                                        <div class="collapse" id="collapse38">
                                                            <ul class="nav-second-level">
                                                                <li>
                                                                    <a class="custom-tab-cmn-btn" data-bs-toggle="collapse"
                                                                        href="#collapse39">
                                                                        2023/06/07 <span class="menu-arrow"><i
                                                                                class="fa-solid fa-plus"></i></span>
                                                                    </a>
                                                                    <div class="collapse" id="collapse39">
                                                                        <ul class="nav-second-level">
                                                                            <li>
                                                                                <a href="cricket-list-other(4).html"
                                                                                    class="caret-right-play">
                                                                                    <span><i
                                                                                            class="fas fa-caret-right"></i></span>
                                                                                    Test X v Test Y
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <a class="custom-tab-cmn-btn" data-bs-toggle="collapse"
                                                            href="#collapse46">
                                                            Womens One Day Internationals<span class="menu-arrow"><i
                                                                    class="fa-solid fa-plus"></i></span>
                                                        </a>
                                                        <div class="collapse" id="collapse46">
                                                            <ul class="nav-second-level">
                                                                <li>
                                                                    <a class="custom-tab-cmn-btn" data-bs-toggle="collapse"
                                                                        href="#collapse47">
                                                                        2023/06/07 <span class="menu-arrow"><i
                                                                                class="fa-solid fa-plus"></i></span>
                                                                    </a>
                                                                    <div class="collapse" id="collapse47">
                                                                        <ul class="nav-second-level">
                                                                            <li>
                                                                                <a href="cricket-list-other(5).html"
                                                                                    class="caret-right-play">
                                                                                    <span><i
                                                                                            class="fas fa-caret-right"></i></span>
                                                                                    England Women v Australia Women
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-2" v-else>
        <div class="sidebar">
            <div class="accordion" id="accordionExample">

                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button sidebar-title custom-tab-cmn-btn" data-bs-toggle="collapse"
                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <span>Racing Sport</span>
                        </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <nav class="collapse casino show">
                                <ul>
                                    <li class="nav-item">
                                        <a href="" class="nav-link"><span class="new-launch-text">Horse Racing</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="livecasino.html" class="nav-link"><span>Greyhound Racing</span></a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>

                <div class="accordion-item" id="coll-list">
                    <h2 class="accordion-header" id="headingtwo">
                        <button class="accordion-button sidebar-title custom-tab-cmn-btn" data-bs-toggle="collapse"
                            data-bs-target="#collapsetwo" aria-expanded="true" aria-controls="collapsetwo">
                            <span>All Sports</span>
                        </button>
                    </h2>
                    <div id="collapsetwo" class="accordion-collapse collapse show" aria-labelledby="headingtwo"
                        data-bs-parent="#coll-list">
                        <div class="accordion-body">
                            <aside class="bd-sidebar">
                                <div class="left-side-menu bd-links" id="sidebar-menu">
                                    <ul class="scroll-manu-side" id="side-menu">
                                        <li class="cum-list-game">
                                            <a data-bs-toggle="collapse" href="#collapse1"
                                                class="collapsed custom-tab-cmn-btn custom-tab-cmn-btn"
                                                aria-expanded="false">
                                                <span class="main-heading">Football </span><span class="menu-arrow"><i
                                                        class="fa-solid fa-plus"></i></span>
                                            </a>
                                            <div class="collapse" id="collapse1">
                                                <ul class="nav-second-level">
                                                    <li>
                                                        <a class="custom-tab-cmn-btn" data-bs-toggle="collapse"
                                                            href="#collapse44">
                                                            ATP - SINGLES TORONTO (CANADA)<span class="menu-arrow"><i
                                                                    class="fa-solid fa-plus"></i></span>
                                                        </a>
                                                        <div class="collapse" id="collapse44">
                                                            <ul class="nav-second-level">
                                                                <li>
                                                                    <a class="custom-tab-cmn-btn" data-bs-toggle="collapse"
                                                                        href="#collapse55">
                                                                        2023/06/07 <span class="menu-arrow"><i
                                                                                class="fa-solid fa-plus"></i></span>
                                                                    </a>
                                                                    <div class="collapse" id="collapse55">
                                                                        <ul class="nav-second-level">
                                                                            <li>
                                                                                <a href="football-detail-list(1).html"
                                                                                    class="caret-right-play">
                                                                                    <span><i
                                                                                            class="fas fa-caret-right"></i></span>
                                                                                    Sonego v Murray
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="football-detail-list(2).html"
                                                                                    class="caret-right-play">
                                                                                    <span><i
                                                                                            class="fas fa-caret-right"></i></span>Vukic
                                                                                    v Coric
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li class="cum-list-game">
                                            <a class="custom-tab-cmn-btn" data-bs-toggle="collapse" href="#collapse3">
                                                <span class="main-heading"> Tennis </span><span class="menu-arrow"><i
                                                        class="fa-solid fa-plus"></i></span>
                                            </a>
                                            <div class="collapse" id="collapse3">
                                                <ul class="nav-second-level">
                                                    <li>
                                                        <a class="custom-tab-cmn-btn" data-bs-toggle="collapse"
                                                            href="#collapse4">
                                                            ATP - SINGLES TORONTO (CANADA)<span class="menu-arrow"><i
                                                                    class="fa-solid fa-plus"></i></span>
                                                        </a>
                                                        <div class="collapse" id="collapse4">
                                                            <ul class="nav-second-level">
                                                                <li>
                                                                    <a class="custom-tab-cmn-btn" data-bs-toggle="collapse"
                                                                        href="#collapse5">
                                                                        2023/06/07 <span class="menu-arrow"><i
                                                                                class="fa-solid fa-plus"></i></span>
                                                                    </a>
                                                                    <div class="collapse" id="collapse5">
                                                                        <ul class="nav-second-level">
                                                                            <li>
                                                                                <a href="Tennis-detail-list(1).html"
                                                                                    class="caret-right-play">
                                                                                    <span><i
                                                                                            class="fas fa-caret-right"></i></span>
                                                                                    Sonego v Murray
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="Tennis-detail-list(2).html"
                                                                                    class="caret-right-play">
                                                                                    <span><i
                                                                                            class="fas fa-caret-right"></i></span>Vukic
                                                                                    v Coric
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li class="cum-list-game">
                                            <a class="custom-tab-cmn-btn" data-bs-toggle="collapse" href="#collapse8">
                                                <span class="main-heading">Cricket</span><span class="menu-arrow"><i
                                                        class="fa-solid fa-plus"></i></span>
                                            </a>
                                            <div class="collapse" id="collapse8">
                                                <ul class="nav-second-level">
                                                    <li>
                                                        <a class="custom-tab-cmn-btn" data-bs-toggle="collapse"
                                                            href="#collapse9">
                                                            Ashes Common Market 2023 <span class="menu-arrow"><i
                                                                    class="fa-solid fa-plus"></i></span>
                                                        </a>
                                                        <div class="collapse" id="collapse9">
                                                            <ul class="nav-second-level">
                                                                <li>
                                                                    <a class="custom-tab-cmn-btn" data-bs-toggle="collapse"
                                                                        href="#collapse10">
                                                                        2023/06/07 <span class="menu-arrow"><i
                                                                                class="fa-solid fa-plus"></i></span>
                                                                    </a>
                                                                    <div class="collapse" id="collapse10">
                                                                        <ul class="nav-second-level">
                                                                            <li>
                                                                                <a href="cricket-list-other(1).html"
                                                                                    class="caret-right-play">
                                                                                    <span><i
                                                                                            class="fas fa-caret-right"></i></span>
                                                                                    Ashes Common Market 2023
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <a class="custom-tab-cmn-btn" data-bs-toggle="collapse"
                                                            href="#collapse18">
                                                            County Championship Division 1 <span class="menu-arrow"><i
                                                                    class="fa-solid fa-plus"></i></span>
                                                        </a>
                                                        <div class="collapse" id="collapse18">
                                                            <ul class="nav-second-level">
                                                                <li>
                                                                    <a class="custom-tab-cmn-btn" data-bs-toggle="collapse"
                                                                        href="#collapse19">
                                                                        2023/06/07 <span class="menu-arrow"><i
                                                                                class="fa-solid fa-plus"></i></span>
                                                                    </a>
                                                                    <div class="collapse" id="collapse19">
                                                                        <ul class="nav-second-level">
                                                                            <li>
                                                                                <a href="cricket-list-other(2).html"
                                                                                    class="caret-right-play">
                                                                                    <span><i
                                                                                            class="fas fa-caret-right"></i></span>
                                                                                    Surrey v Nottinghamshire
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="cricket-list-other(2).html"
                                                                                    class="caret-right-play">
                                                                                    <span><i
                                                                                            class="fas fa-caret-right"></i></span>
                                                                                    Middlesex v Northamptonshire
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <a class="custom-tab-cmn-btn" data-bs-toggle="collapse"
                                                            href="#collapse36">
                                                            Test Matches <span class="menu-arrow"><i
                                                                    class="fa-solid fa-plus"></i></span>
                                                        </a>
                                                        <div class="collapse" id="collapse36">
                                                            <ul class="nav-second-level">
                                                                <li>
                                                                    <a class="custom-tab-cmn-btn" data-bs-toggle="collapse"
                                                                        href="#collapse37">
                                                                        2023/06/07 <span class="menu-arrow"><i
                                                                                class="fa-solid fa-plus"></i></span>
                                                                    </a>
                                                                    <div class="collapse" id="collapse37">
                                                                        <ul class="nav-second-level">
                                                                            <li>
                                                                                <a href="cricket-list-other(3).html"
                                                                                    class="caret-right-play">
                                                                                    <span><i
                                                                                            class="fas fa-caret-right"></i></span>
                                                                                    West Indies v India
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <a class="custom-tab-cmn-btn" data-bs-toggle="collapse"
                                                            href="#collapse38">
                                                            Testing <span class="menu-arrow"><i
                                                                    class="fa-solid fa-plus"></i></span>
                                                        </a>
                                                        <div class="collapse" id="collapse38">
                                                            <ul class="nav-second-level">
                                                                <li>
                                                                    <a class="custom-tab-cmn-btn" data-bs-toggle="collapse"
                                                                        href="#collapse39">
                                                                        2023/06/07 <span class="menu-arrow"><i
                                                                                class="fa-solid fa-plus"></i></span>
                                                                    </a>
                                                                    <div class="collapse" id="collapse39">
                                                                        <ul class="nav-second-level">
                                                                            <li>
                                                                                <a href="cricket-list-other(4).html"
                                                                                    class="caret-right-play">
                                                                                    <span><i
                                                                                            class="fas fa-caret-right"></i></span>
                                                                                    Test X v Test Y
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <a class="custom-tab-cmn-btn" data-bs-toggle="collapse"
                                                            href="#collapse46">
                                                            Womens One Day Internationals<span class="menu-arrow"><i
                                                                    class="fa-solid fa-plus"></i></span>
                                                        </a>
                                                        <div class="collapse" id="collapse46">
                                                            <ul class="nav-second-level">
                                                                <li>
                                                                    <a class="custom-tab-cmn-btn" data-bs-toggle="collapse"
                                                                        href="#collapse47">
                                                                        2023/06/07 <span class="menu-arrow"><i
                                                                                class="fa-solid fa-plus"></i></span>
                                                                    </a>
                                                                    <div class="collapse" id="collapse47">
                                                                        <ul class="nav-second-level">
                                                                            <li>
                                                                                <a href="cricket-list-other(5).html"
                                                                                    class="caret-right-play">
                                                                                    <span><i
                                                                                            class="fas fa-caret-right"></i></span>
                                                                                    England Women v Australia Women
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>

                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingtwo">
                        <button class="accordion-button sidebar-title custom-tab-cmn-btn" data-bs-toggle="collapse"
                            data-bs-target="#collapsetwo" aria-expanded="true" aria-controls="collapseOne">
                            <span>Others</span>
                        </button>
                    </h2>
                    <div id="collapsetwo" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <nav class="collapse casino show">
                                <ul>
                                    <li class="nav-item">
                                        <a href="" class="nav-link"><span class="new-launch-text">Matka</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="livecasino.html" class="nav-link"><span>Casino</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="" class="nav-link"><span class="new-launch-text">Int Casino</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="livecasino.html" class="nav-link"><span>Virtual sports</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="" class="nav-link"><span class="new-launch-text">Fantasy cricket</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="livecasino.html" class="nav-link"><span>Sportsbook</span></a>
                                    </li>
                                </ul>
                            </nav>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div></template>

<script>
// import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
// import api from '@/shared/services/api';
// import * as apiName from '@/shared/services/urls';
export default {
    name: "SideMenu",
    data() {
        return {
        }
    },
    mounted() {

    },
    methods: {
        checkAuth() {
            return this.$store?.getters?.isAuthenticated
        },
        logoutServiceCall() {
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.get(apiName.LOGOUT_API, { headers }).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0])
                        }
                        else {
                            // this.$emit('success-modal', response?.data?.message);
                            this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
                            this.$store.dispatch('resetAllState');
                            this.$router.push('/');
                            this.$refs.sidemenuClose.click();
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    console.log("Error Login : ", error);
                    this.$emit('error-modal', error[0]);
                }
            });
        },
    }
};
</script>