<template>
    <footer loading="lazy">
      <div class="footer-bottom">
          <p class="copyright">
            © Copyright 2016. All Rights Reserved. Powered by SIGMAEXCH.
          </p>
      </div>
    </footer>
    <div class="meassage-sec whatsup-btn-fixed" v-if="siteSettings && siteSettings?.business_type == 2">
        <a href="javascript:void(0);" @click="getParentWhatsappNumber" data-bs-toggle="modal" data-bs-target="#whatsup-depositModal">
            <div class="whatsapp-icon-btn">
                <img src="@/assets/images/whatsapp-icon-new.svg" alt="whatsapp">
            </div>
        </a>
        <!-- <ChatComponent @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"></ChatComponent> -->
      </div>
    <!--Whatsup Deposit Modal -->
      <div class="modal fade whatsup-modal-wrapper" id="whatsup-depositModal" tabindex="-1" aria-labelledby="whatsupModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Customer Support</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body whatsup-modal-information">
              <div class="whatsaap-support-btn-list" v-if="whatsappNumbers && whatsappNumbers.length > 0">
                <div class="support-call-now" v-for="(data, index) in whatsappNumbers" :key="index">
                    <a :href="'https://wa.me/' + data.whatsapp_number" target="_blank">
                      <div class="support-whatsapp-icon"><img src="@/assets/images/whatsapp-icon-new.svg" alt="whatsapp"></div>
                      <span class="btn thm-btn">{{ data.category_name }}</span>
                    </a>      
                </div>
              </div>
              <div v-else-if="siteSettings && (siteSettings.social_link?.whatsappno1 || siteSettings.social_link?.whatsappno2)">
                <template v-for="number in [siteSettings.social_link?.whatsappno1, siteSettings.social_link?.whatsappno2]">
                  <a v-if="number" 
                      :key="number" 
                      :href="'https://wa.me/' + number" 
                      target="_blank" 
                      class="btn thm-btn call-now">
                      <img src="@/assets/images/whatsapp-icon.webp" alt="whatsapp">{{ number }}
                  </a>
                </template>
              </div>
              <div v-else>
                <p>{{translatedLangData('no-whatsapp-avail','No WhatsApp numbers available.')}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    <!--Whatsup Deposit Modal -->   
</template>



<script>
export default {
  name: "Footer",
  inject: ['translatedLangData'],
  computed: {
        siteSettings() {
            return this.$store.getters.siteSettings
        }
  },
  data() {
        return {
            whatsappNumbers:[],
        }
  },
  methods: {
    checkIsLogin() {
      return this.$store.getters.isAuthenticated;
    },
    async getParentWhatsappNumber() {
            if (this.checkIsLogin()) {
                this.loading = true;
                try {
                    const cpiExists = this.$store.getters.stateUser?.cpi;
                    const supportNumbers = this.siteSettings.support_numbers || [];
                    if (!cpiExists) {
                        return;
                    }

                    const now = new Date();
                    const totalMinutes = now.getHours() * 60 + now.getMinutes();

                    this.whatsappNumbers = supportNumbers
                        .filter(entry => entry.id === cpiExists)
                        .flatMap(entry => entry.categories.map(category => {
                            const whatsappNumbers = category.value.split(',');
                            const selectedNumberIndex = totalMinutes % whatsappNumbers.length;
                            return {
                                category_name: category.name,
                                whatsapp_number: whatsappNumbers[selectedNumberIndex]
                            };
                        }));
                    this.loading = false;
                } catch (error) {
                    this.loading = false;
                    this.showErrorModalFunc(error[0]);
                }
            }
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
  },
};
</script>

<style scoped>
.footer-list-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>