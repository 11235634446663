<template>
    <header class="heading-section pb-0" loading="lazy">

        <div class="flex-area">
            <div class="logo header-logo">
                <router-link :to="{ name: 'Home' }" class="logo">
                    <i class="fa-sharp fa-solid fa-house-chimney mobile-logo"></i>
                    <img :src="siteSettings?.aws_url + siteSettings?.storage_path.domain_image + siteSettings?.logo"
                        alt="logo">
                </router-link>
            </div>
            <div class="nav-right-side" v-if="$store.getters.isAuthenticated">
                <ul class="cum-list">
                    <div class="deposit-and-withdrawal-cmn-btn" v-if="siteSettings?.business_type == 2 && $store.getters.isAuthenticated">
                    <router-link :to="{ name: 'wallet-gateway' }" class="deposit-btn12 bg-success"><img alt="" src="@/assets/images/deposit-icon.webp"> {{translatedLangData('deposit', 'Deposit')}}</router-link>
                    <router-link :to="{ name: 'wallet-gateway' }" class="withdrawal-btn12 bg-danger"><img alt="" src="@/assets/images/withdrawal-icon.webp"> {{translatedLangData('withdrawal', 'Withdrawal')}}</router-link>
                </div>
                    <li class="search">
                        <input type="search" name="search" placeholder="All Events" class="search-input-show" /> <span
                            class="open-search"> <i class="fas fa-search-plus"></i> </span>
                    </li>
                    <li class="rules">
                        <router-link :to="{ name: 'Rules' }"> <b> {{translatedLangData('rules', 'Rules')}}</b> </router-link>
                    </li>
                    <li class="balance">
                        <div class="amount">
                            <span>{{translatedLangData('balance', 'Balance')}}:</span> <b><span>{{ parseInt(stateUser?.balance).toFixed(2) }}</span></b>
                        </div>
                        <!-- <div class="exposure" id="divcheck" v-if="balanceExpoDisplay.includes('exposure')">
                            <a href="Javascript:void(0);" data-bs-toggle="modal" data-bs-target="#exampleModal"
                                @click="callForMyMarket()">
                                <span class="t-underline">Exp:</span> <span class="t-underline">{{
                                    (-1) * (stateUser?.expl) }}</span>
                            </a>
                        </div> -->
                    </li>
                    <li class="account">
                        <div class="mobile-amount" id="balancecheck" v-if="balanceExpoDisplay.includes('balance')">
                            <button class="bank-amount">
                                <i class="fa-solid fa-building-columns"> </i>&nbsp;<b>{{ parseInt(stateUser?.balance).toFixed(2) }}</b>
                            </button>
                        </div>
                        <div class="d-flex exposure-flex">
                            <div class="exposure" id="divcheck" v-if="balanceExpoDisplay.includes('exposure')">
                            <a href="Javascript:void(0);" data-bs-toggle="modal" data-bs-target="#exampleModal"
                                @click="callForMyMarket()">
                                <span class="t-underline">{{translatedLangData('exposure', 'Exp')}}:</span> <span class="t-underline">{{
                                    parseInt((-1) * (stateUser?.expl)).toFixed(2) }}</span>
                            </a>
                        </div>
                        <div class="dropdown-account headAcc">
                            <button role="button" class="btn dropdown-toggle" type="button" id="dropdownMenuButton1"
                                data-bs-toggle="dropdown" aria-expanded="false" aria-controls="acc-menu-btn123">
                                <span>{{ stateUser?.userid }}</span>
                                <img src="@/assets/images/down.webp" alt="">
                            </button>

                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">

                                <li class="">
                                    <div class="setting-user-checkbox">

                                        <div class="user-details-r">
                                            <label class="form-check-label" for="flexSwitchCheckDefault" >{{translatedLangData('one-click-bet', 'One Click Bet')}}</label>
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" id="flexSwitchCheckDefault" type="checkbox" v-model="clickBetCheck"
                                                    @change="betCheckChangeFunc()">
                                            </div>
                                            <div v-if="clickBetCheck && getClickBetSelectedValue()"
                                                @click="$refs.open_click_bet_value.click()">
                                                <label>{{ getClickBetSelectedValue() }}</label>
                                            </div>
                                        </div>
                                        <a style="display: none;" ref="open_click_bet_value" data-bs-toggle="modal"
                                            data-bs-target="#clickBetValues"></a>

                                    </div>
                                </li>
                                <div class="welcome-bonus-bx" v-if="siteSettings.business_type == 2">
                                    <!-- <li class="bonnus">
                                        <a>
                                            <span class="earn-bonus-text">{{translatedLangData('weekly-reward', 'Weekly Reward')}}</span>
                                            <span>0</span>
                                        </a>
                                        <span class="float-end" data-bs-target="#weeklyrewardModal" data-bs-toggle="modal"><img class="info-icon-bonus" src="@/assets/images/info-icon.webp" /></span>
                                    </li> -->
                                    <li class="bonnus" v-if="lockedEarnedData">
                                        <a href="Javascript:void(0);" >
                                            <span class="earn-bonus-text">{{translatedLangData('earn-bonus', 'Earn Bonus')}}</span>
                                            <span>{{ lockedEarnedData?.earnBonus }}</span>
                                        </a>
                                        <span class="float-end" data-bs-target="#informationModal" data-bs-toggle="modal" @click="getRules()" ><img class="info-icon-bonus" src="@/assets/images/info-icon.webp" /></span>
                                    </li>
                                    <li class="bonnus locked-bonus-bg" @click="openBonusLogs" v-if="lockedEarnedData">
                                        <a href="Javascript:void(0);">
                                            <span class="earn-bonus-text">{{translatedLangData('locked-bonus', 'Locked Bonus')}}</span>
                                            <span>{{ lockedEarnedData?.lockedBonus }}</span>
                                        </a>
                                        <span class="float-end"><img class="info-icon-bonus" src="@/assets/images/info-icon.webp" /></span>
                                    </li>
                                    <div class="welcome-bonus-bg">
                                        <li class="bonnus" v-if="lockedEarnedData">
                                            <a href="Javascript:void(0);">
                                                <span class="earn-bonus-text">{{translatedLangData('welcome-bonus', 'Welcome Bonus')}}</span>
                                                <span>{{ lockedEarnedData?.welcomeBonus }}</span>
                                            </a>
                                            <!-- <span class="float-end"><img class="info-icon-bonus" src="@/assets/images/info-icon.webp" /></span> -->
                                        </li>
                                        <li class="bonnus" v-if="lockedEarnedData">
                                            <a href="Javascript:void(0);">
                                                <span class="earn-bonus-text">{{translatedLangData('play-with-bonus', 'Play with Bonus')}}</span>
                                            </a>
                                            <div class="menu-heading-con">
                                                <div class="form-check form-switch m-0 p-0">
                                                    <input class="form-check-input" type="checkbox"
                                                    id="settingCheckDefaults" v-model="getWalletType" @change="updateWalletType"  />
                                                </div>
                                            </div>
                                        </li>
                                    </div>
                                    <li class="refer-earn-btn-sidebar">
                                        <router-link :to="{ name: 'refer-earn' }">
                                            <a href="Javascript:void(0);"><span>{{translatedLangData('refer-and-earn', 'Refer & Earn')}}</span></a>
                                        </router-link>                                        
                                    </li>
                                </div>
                                <li>
                                    <a href="#select-language-btn" data-bs-toggle="modal">
                                        <!-- <img src="@/assets/images/passwordHistory.webp" alt="" /> -->
                                        <span>{{translatedLangData('select-language', 'Select Language')}}</span>
                                    </a>
                                </li>

                                <li>
                                    <router-link :to="{ name: 'AccountStatement' }">
                                        <!-- <img src="@/assets/images/account.webp" alt="" /> -->
                                        <span>{{translatedLangData('account-statement', 'Account Statement')}}</span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'profit-and-loss' }">
                                        <!-- <img src="@/assets/images/Profit.webp" alt="" /> -->
                                        <span>{{translatedLangData('profit-loss-report', 'Profit Loss Report')}}</span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'bet-history' }">
                                        <!-- <img src="@/assets/images/betHistory.webp" alt="" /> -->
                                        <span>{{translatedLangData('bet-history', 'Bet History')}}</span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'unsettled-bet' }">
                                        <!-- <img src="@/assets/images/betHistory.webp" alt="" /> -->
                                        <span>{{translatedLangData('unsettled-bets', 'Unsettled Bets')}}</span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'set-button-value' }">
                                        <!-- <img src="@/assets/images/betHistory.webp" alt="" /> -->
                                        <span>{{translatedLangData('set-button-values', 'Set Button Values')}}</span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'change-password' }">
                                        <!-- <img src="@/assets/images/passwordHistory.webp" alt="" /> -->
                                        <span>{{translatedLangData('change-password', 'Change Password')}}</span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'Rules' }">
                                        <span>{{translatedLangData('rules', 'Rules')}}</span>
                                    </router-link>
                                </li>
                                <!-- <li v-if="siteSettings?.business_type == 2">
                                    <router-link :to="{ name: 'wallet-gateway' }">
                                        <img src="@/assets/images/wallet (1).png" alt="" />
                                        <span>wallet</span>
                                    </router-link>
                                </li> -->

                                <!-- <li>
                                        <router-link :to="{name: 'refer-earn'}">
                                            <img src="@/assets/images/giftbox.webp" alt="" />
                                            <span>Refer & Earn</span>
                                        </router-link>
                                    </li> -->

                                
<!-- 
                                <li>
                                    <router-link :to="{ name: 'casino-report-history' }">
                                        <img src="@/assets/images/betHistory.webp" alt="" />
                                        <span>Casino Report History</span>
                                    </router-link>
                                </li> -->

                                <!-- <li>
                                        <router-link :to="{ name: 'Security-and-auth' }">
                                            <img src="@/assets/images/betHistory.webp" alt="" />
                                            <span>Security Auth Verification</span>
                                        </router-link>
                                    </li> -->
                                <!-- <li>
                                    <a href="#" class="dropdown-item mobile-nav">
                                        Balance
                                        <div class="custom-control custom-checkbox float-right"><input type="checkbox"
                                                id="customCheck" class="custom-control-input" v-model="balanceExpoDisplay"
                                                :value="'balance'" @change="updateBalExpDisplay()" /> <label
                                                for="customCheck" class="custom-control-label"></label></div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" class="dropdown-item mobile-nav">
                                        Exposure
                                        <div class="custom-control custom-checkbox float-right">
                                            <input type="checkbox" id="customCheck-one" class="custom-control-input"
                                                v-model="balanceExpoDisplay" :value="'exposure'"
                                                @change="updateBalExpDisplay()" /> <label for="customCheck-one"
                                                class="custom-control-label"></label>
                                        </div>
                                    </a>
                                </li> -->
                                <li class="log-out">
                                    <a href="Javascript:void(0);" @click="logoutBtnClick()"><span>{{translatedLangData('logout', 'Logout')}}</span></a>
                                </li>
                            </ul>
                        </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="login-and-register-btn" v-else>
                <ul class="cmn-ul-list">  
                    <li>
                        <button type="button" class="btn thm-but" data-bs-target="#signupModal" data-bs-toggle="modal">{{translatedLangData('register', 'Signup')}}</button>
                    </li>
                    <li>
                        <button type="button" class="btn cmn-bdr-btn" data-bs-target="#loginModal" data-bs-toggle="modal">{{translatedLangData('log-in', 'Login')}}</button>
                    </li>                  
                    <!-- <li>
                        <router-link :to="{name : 'SignUp'}" class="btn thm-but" href="Javascript:void(0);">Signup</router-link>
                    </li>
                    <li>
                        <router-link :to="{name : 'Login'}" class="btn cmn-bdr-btn" href="Javascript:void(0);">Login</router-link>
                    </li> -->
                </ul>
            </div>
        </div>

        <div class="marque">
            <div class="search-box-container">
                <div class="deposit-and-withdrawal-cmn-btn" v-if="siteSettings?.business_type == 2 && $store.getters.isAuthenticated">
                    <router-link :to="{ name: 'wallet-gateway' }" class="deposit-btn12 bg-success"><img alt="" src="@/assets/images/deposit-icon.webp"> {{translatedLangData('deposit', 'Deposit')}}</router-link>
                    <router-link :to="{ name: 'wallet-gateway' }" class="withdrawal-btn12 bg-danger"><img alt="" src="@/assets/images/withdrawal-icon.webp">{{translatedLangData('withdrawal', 'Withdrawal')}}</router-link>
                </div>
                <div class="search-box-mobile">
                    <button class="btn-search" @click="clickOnSearch()"><i class="fas fa-search"></i></button>
                    <input type="text" ref="searchInput" class="input-search" @keyup="searchIcon"
                        v-model="searchText" @blur="clearSearch()" />
                </div>               
                <div class="suggest-list" id="btn1"
                    v-if="searchResult && searchResult.matches && searchResult.matches.length > 0">
                    <ul>
                        <li v-for="(search, sIndex) in searchResult.matches" :key="sIndex">
                            <a href="Javascript:void(0);" class="search-data" @click="gotoEvent(search)">
                                <div class="suggest-category-list">
                                    <b class="text-capitalize">{{ search.sport_name == 'soccer' ? 'Football' : search.sport_name  }}</b>
                                    <!-- <b>| MATCH_ODDS</b> -->
                                    <div class="date-and-time">
                                        {{ formatTime(search.event_date) }}
                                    </div>
                                </div>
                                <div class="game-teams">{{ search.event_name }}</div>
                            </a>
                        </li>
                    </ul>
                </div>
           
            </div>
            <template v-if="siteSettings && siteSettings.announcement && siteSettings.announcement.msg">
                <marquee :style="{ color: siteSettings?.announcement?.color_code }" scrollamount="3" class="searchClose">{{siteSettings.announcement.msg}}</marquee>            
            </template>
       </div>
       
    <div class="latest-event" v-if="checkForShow('recommendedEvent')">
        <div class="latest-event-item" v-for="(recMatch, rIndex) in getRecommendedMatch" :key="rIndex">
            <a @click="this.$router.push({name: 'SportsEventDetail', params: { event_id: recMatch.event_id } })" class="new-launch-text">
                <img alt="" src="@/assets/images/cricket-img.png" v-if="recMatch.sport_id == 4">
                <img alt="" src="@/assets/images/tennis-img.png" v-if="recMatch.sport_id == 2">
                <img alt="" src="@/assets/images/1.webp" v-if="recMatch.sport_id == 1">
                <span>{{ recMatch.event_name }}</span>
            </a>
        </div>
    </div>
    <!-- header-flag -->
    <div class="header-b-menu" v-if="checkForShow('headerFlag')">
        <div :class="index % 2 == 0  ? 'ipl' : 'election'" v-for="(item, index) in sportsList" :key="index" @click="goToSportEvent(item)">
            <a href="Javascript:void(0);" class="text-link router-link-exact-active router-link-active">{{ item.name }}</a>
        </div>
        <!-- <div class="election">
            <a href="#" class="text-link">Lok Sabha 2024</a>
        </div> -->
    </div>


    <div class="mobile-view-sec" loading="lazy" v-if="checkForShow('sportsEvent')">
        <div class="game-nav-bar">
            <ul class="nav">
                <li class="nav-item" :class="{ active: $route.name == 'Home' }">
                    <router-link to="/" class="nav-link">
                        {{translatedLangData('inplay-footer-label', 'In-play')}}
                    </router-link>
                </li>
                <li class="nav-item" :class="{ active: $route.name == 'Sports' }">
                    <router-link :to="{ name: 'Sports' }" class="nav-link">
                        {{translatedLangData('sports', 'Sports')}}
                    </router-link>
                </li>
                <li class="nav-item" :class="{ active: $route.name == 'casino-slot' }">
                    <router-link :to="{ name: 'casino-slot' }" href="#" class="nav-link">
                        {{translatedLangData('casino', 'Casino')}}
                    </router-link>
                </li>
                <li class="nav-item" :class="{ active: $route.name == 'sports-book' }">
                    <a href="Javascript:void(0);" class="nav-link" @click="gotoSportsbook()">
                        {{translatedLangData('sportsbook', 'Sports Book')}}                        
                    </a>
                </li>
                <li class="nav-item" :class="{ active: $route.name == 'Matka' || $route.path.includes('matka') }">
                    <router-link to="/matka" class="nav-link">
                        {{translatedLangData('matka', 'Matka')}}
                    </router-link>
                </li>
                <li class="nav-item" :class="{ active: $route.name == 'Others' }">
                    <router-link :to="{ name: 'Others' }" class="nav-link">
                        {{translatedLangData('others', 'Others')}}
                    </router-link>
                </li>

                <li class="nav-item" :class="{ active: $route.name == 'cricketFight' || $route.path.includes('cricket-fight')}">
                    <router-link to="/cricket-fight" class="nav-link bd-right">
                        {{translatedLangData('cricket-fight', 'Cricket-Fight')}}
                    </router-link>
                </li>

            </ul>
        </div>
    </div>


    </header>
    

    <Rule />
    <LockBonus v-if="showBonusLogs" @success-modal="showSuccessModalFunc" @error-modal="showErrorModalFunc" @closeBonusLogs="closeBonusLogs()" />
    <BonusInformation :siteRuleData="siteRuleData"/>
    <LanguageModal @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"></LanguageModal>
    <MyMarket ref="myMarket" />
    <OnLoadModal v-if="showPromoModal" @modelShown="showPromoModal = false"></OnLoadModal>
    <ClickBetModal @success-modal="showSuccessModalFunc" @error-modal="showErrorModalFunc" @cancelOneClick = "clickBetCheck = false" />


    <div class="modal fade login-register-modal" id="loginModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <button type="button" class="btn-close top-right-close closeBtnModal" data-bs-dismiss="modal" aria-label="Close" ref="eventDetailFilterModal" @click="this.$refs.login.refreshData"></button>
        <div class="modal-dialog modal-md modal-dialog-centered p-0">           
        <div class="modal-content">           
            <div class="modal-body p-0">
                <Login @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" ref="login"></Login>
            </div>            
        </div>
        </div>
    </div>

    <div class="modal fade login-register-modal" id="signupModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <button type="button" class="btn-close top-right-close closeBtnModal" data-bs-dismiss="modal" aria-label="Close" ref="eventDetailFilterModal" @click="this.$refs.signup.refreshData"></button>
        <div class="modal-dialog modal-md modal-dialog-centered p-0">           
        <div class="modal-content">           
            <div class="modal-body p-0">
                <SignUp @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" ref="signup"></SignUp>
            </div>            
        </div>
        </div>
    </div>
    <div class="modal fade login-register-modal" id="forgotPasswordModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <button type="button" class="btn-close top-right-close closeBtnModal" data-bs-dismiss="modal" aria-label="Close" ref="eventDetailFilterModal" @click="this.$refs.forget.refreshData"></button>
        <div class="modal-dialog modal-md modal-dialog-centered p-0">           
        <div class="modal-content">           
            <div class="modal-body p-0">
                <ForgetPassword @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" ref="forget"></ForgetPassword>
            </div>            
        </div>
        </div>
    </div>
    
</template>
 
<script >
// import SideMenu from "./SideMenu.vue";
import LockBonus from '../modal/LockBonus.vue';
import BonusInformation from '../modal/BonusInformation.vue';
import MyMarket from '../modal/MyMarket.vue';
import Rule from '../modal/Rules.vue';
import OnLoadModal from "@/shared/components/modal/OnLoadModal.vue";
import ClickBetModal from '@/modules/account/components/ClickBetModal.vue';
import LanguageModal from './LanguageModal.vue';
import Login from '@/modules/authorization/components/Login.vue'
import SignUp from '@/modules/authorization/components/SignUp.vue'

import { mapGetters } from 'vuex';
import api from '../../services/api';
import * as apiName from '../../services/urls';
import { AUTH_TOKEN } from "@/shared/constants/cookies-const";
import moment from 'moment';
import { kFormatter } from '@/shared/utils/formatter';
import * as varConstants from '@/shared/constants/var-constants';
import captchaApi from '@/modules/authorization/services/captcha-api';
import ForgetPassword from '@/modules/authorization/components/ForgetPassword.vue';

export default {
    name: "Header",
    inject: ['translatedLangData'],
    components: {
        // SideMenu,
        LockBonus,
        BonusInformation,
        MyMarket,
        Rule,
        OnLoadModal,
        ClickBetModal,
        LanguageModal,
        Login,
        SignUp,
        ForgetPassword
    },
    data() {
        return {
            showPromoModal: true,
            searchResult: null,
            searchText: "",
            clickBetCheck: this.$store?.getters?.isClickBetOn ? this.$store?.getters?.isClickBetOn : false,
            balanceExpoDisplay: [],
            sportsList: [],
            lockedEarnedData: null,
            showBonusLogs: false,
            getWalletType: false,
            siteRuleData: null
        };
    },
    mounted() {
        this.sportsList = this.$store.getters.sports_list?.custom;
        // if (this.getClickBetValue()) {
        //     this.clickBetCheck = true
        // }
        this.balanceExpoDisplay = this.getBalExpDisplay
        if(this.checkIsLogin && this.siteSettings.business_type == 2) {
            this.getLockedEarnedBonus();
            this.getWalletType = this.stateUser?.activeWallet == 'WB';
        } 
    },
    computed: {
        ...mapGetters([
            'siteSettings', 'stateUser', 'getBalExpDisplay', 'getRecommendedMatch'
        ]),
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
        },
    },

    methods: {
        debounce(callback, timer=1000) {
            const context = this;
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
                callback.apply(context);
            }, timer);
        },
        searchIcon(){
            this.debounce(function(){
                  this.searchCasinoMatches()
            }, 1000);
        },
        getRules() {
            this.loading = true;
            if(this.siteRuleData) {
                return;
            }
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.get(apiName.GET_SITE_RULES, { headers }).then(response => {
                this.loading = false;
                this.$store.dispatch('setGlobalLoader', false);
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                        }
                        else {
                            let ruleResp = response.data.data;
                            this.siteRuleData = {}
                            ruleResp.forEach(item => {
                                this.siteRuleData[item.type] = item.content;
                            })                            
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        getLockedEarnedBonus() {
            let authToken = this.$cookies.get(AUTH_TOKEN);
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
            api.get(apiName.GET_LOCKED_EARNED_BONUS, {headers}).then(response => {
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                        }
                        else {
                            this.lockedEarnedData = response.data.data;
                        }
                    }
                }
            }).catch(error => {
                this.$store.dispatch('setGlobalLoader', false);
                if (error) {
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        openBonusLogs() {
            this.showBonusLogs = true;
        },
        closeBonusLogs() {
            setTimeout(() => {
                this.showBonusLogs = false;
            }, 400)
        },
        updateWalletType() {
            let authToken = this.$cookies.get(AUTH_TOKEN);
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
            api.post(apiName.UPDATE_WALLET_TYPE, {} , {headers}).then(response => {
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                        }
                        else {
                            let user = this.stateUser;
                            user.activeWallet = response.data.data.active_wallet;
                            this.$store.dispatch('setUser', user);
                            this.$emit('success-modal', 'Successfully changed');
                            setTimeout(() => {
                                this.$emit('updateAuthToken', true);
                            },2000)
                        }
                    }
                }
            }).catch(error => {
                this.$store.dispatch('setGlobalLoader', false);
                if (error) {
                    this.getWalletType = !this.getWalletType;
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        goToSportEvent(item) {
            if(this.$route.name != 'Sports') {
                this.$router.push({name: 'Sports', params: {type: item.slug ,id : item.id, head_sport: 1}});
            } else {
                const sportComponent = this.$router.getRoutes().find(route => route.name === 'Sports');
                if (sportComponent) {
                    const instance = sportComponent.instances.default;
                    if (instance && typeof instance.getSportsList === 'function') {
                        instance.getSportsList(item);
                    }
                }
            }
        },
        checkForBackArrow() {
            let routeName = this.$route.name;
            let routeType = this.$route.params.type;
            switch (routeName) {
                //checking for show three dot menu
                case "Home":
                case "Inplay":
                    return true;
                case "games":
                    return routeType == "GAMES";
                default:
                    return false;
            }
        },
        checkForShow(event) {
            let routeName = this.$route.name;
            if(event == 'sportsEvent'){
                let noShowRoutes = ['SportsEventDetail','sports-book','AccountStatement','profit-and-loss','unsettled-bet','set-button-value','ProfitLossDetail','bet-history','change-password','Rules','wallet-gateway']
                return !noShowRoutes.includes(routeName)
            }
            else if(event == 'recommendedEvent'){
                let noShowRoutes = ['SportsEventDetail','AccountStatement','profit-and-loss','unsettled-bet','set-button-value','ProfitLossDetail','bet-history','change-password','Rules','wallet-gateway']
                return !noShowRoutes.includes(routeName)
            }
            else{
                let noShowRoutes = []
                return !noShowRoutes.includes(routeName)
            }
            
        },
        callVoiceSearchFlag() {
            this.$emit("voice-search-flag");
        },
        searchCasinoMatches() {
            try {
                this.searchResult = null;
                this.loading = true;
                let headers = {
                    'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
                }
                let data = {
                    "search": this.searchText
                };
                api.post(apiName.SEARCH, data, { headers }).then(response => {
                    this.loading = false;
                    if (response && response.status && response.status == 200) {
                        let resData = response.data.data ?? null;
                        this.searchResult = resData;
                    } else {
                        // this.showErrorModalFunc(response.data.debug[0]);
                    }
                }).catch(error => {
                    this.loading = false;
                    if (error) {
                        console.log(error)
                        // this.showErrorModalFunc(error[0]);
                    }
                });
            } catch (error) {
                console.log(error)

            }
        },
        formatTime(date) {
            return moment(date).format('DD/MM/YYYY hh:mm:ss A');
        },
        gotoEvent(item) {
            this.$router.push({ name: 'SportsEventDetail', params: { event_id: item.event_id } });
            this.searchResult = null;
            this.searchText = ''
        },
        clickOnSearch() {
            this.$refs.searchInput.focus();
        },
        clearSearch() {
            setTimeout(() => {
                this.searchResult = null;
                this.searchText = ''
            }, 500)
        },
        betCheckChangeFunc() {
            if (this.clickBetCheck) {
                this.$refs.open_click_bet_value.click();
            }
            else {
                this.$store.dispatch('setIsClickBetOn', false);
                this.showSuccessModalFunc('One Click Bet is Inactive.')
                // this.$store.dispatch('setClickBetValue', null);
            }
        },
        getClickBetSelectedValue() {
            let inputValues = this.getClickBetValue();
            if (inputValues)
                return inputValues.filter(item => item.isSelected)[0].value;
        },
        getKFormatter(num) {
            return kFormatter(num);
        },
        getClickBetValue() {
            return this.$store.getters.clickBetValue ? [...this.$store.getters.clickBetValue] : null;
        },
        callForMyMarket() {
            this.$refs.myMarket.getMyMarketsServiceCall('sport');
        },
        updateBalExpDisplay() {
            this.$store.dispatch('setBalExpDisplay', this.balanceExpoDisplay)
        },
        logoutBtnClick() {
            this.logoutServiceCall();
        },
        logoutServiceCall() {

            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.get(apiName.LOGOUT_API, { headers }).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0])
                        }
                        else {
                            this.$emit('success-modal', response?.data?.message);
                            // remove all cookie
                            this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
                            this.$store.dispatch('resetAllState');
                            this.$router.push({ name: 'Login' });
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        gotoSportsbook() {
            if(this.checkIsLogin) {
                this.$router.push({ name: 'sports-book' })
            } else {
                this.$emit('error-modal', this.translatedLangData('login-for-sportsbook','Please Login to Access Sportsbook!!'));
            }
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
    },
};
</script>

<style></style>