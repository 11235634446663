const OthersModule = () => import(/* webpackChunkName: "games-module" */ './views/Module.vue');
const Others = () => import(/* webpackChunkName: "games" */ './views/Others.vue');

const OthersRoutes = {
    path: '/',
    component: OthersModule,
    children: [
        {
            path: 'others',
            name: 'Others',
            component: Others
        }
    ],
}

export default OthersRoutes;