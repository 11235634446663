const CasinSlotModule = () => import(/* webpackChunkName: "casino-slot-module" */ './views/Module.vue');
const CasinoSlot = () => import(/* webpackChunkName: "casino-slot" */ './views/CasinoSlot.vue');

const CasinoSlotRoutes = {
    path: '/',
    component: CasinSlotModule,
    children: [
        {
            path: 'casino-slot',
            name: 'casino-slot',
            component: CasinoSlot
        }
    ],
}

export default CasinoSlotRoutes;