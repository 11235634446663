<template>
    <section class="desktop-navbar desktop-tabs" loading="lazy">
        <div class="container-fluid">
            <ul class="list-unstyled">

                <li class="nav-item">
                    <router-link :to="{name: 'Home'}" class="cum-list home-padding" :class="{active : routeName == 'Home' }"><b>Home</b></router-link>
                </li>

                <li class="nav-item" v-for="(sport, sIndex) in non_custom_sports" :key="sIndex">
                    <router-link :to="{name: 'Sports', params: {type: sport.slug, id: sport.id}}" class="cum-list" :class="{active : routeName == 'Sports' && $route.params.type == sport.slug }">
                        <b>{{ sport.name }}</b>
                    </router-link>
                </li>

                <li class="nav-item">
                    <router-link :to="{name: 'games'}" class="cum-list" :class="{active : routeName == 'games' }">
                        <span><b>casino</b></span>
                    </router-link>
                </li>

                <li class="nav-item">
                    <router-link :to="{name: 'racing-category', params: {type: 'HORSE_RACE'}}" class="cum-list" :class="{active : routeName == 'racing-category' && $route.params.type == 'HORSE_RACE' }">
                        <span><b>Horse Racing</b></span>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="{name: 'racing-category', params: {type: 'GREY_HOUND'}}" class="cum-list" :class="{active : routeName == 'racing-category' && $route.params.type == 'GREY_HOUND' }">
                        <span><b> Greyhound Racing </b></span>
                    </router-link>
                </li>

                <li class="nav-item">
                    <router-link to="">
                        <span><b> fantasy cricket</b></span>
                    </router-link>
                </li>
               
                <li class="nav-item">
                    <router-link :to="{name: 'virtual-sports'}" class="cum-list" :class="{active : routeName == 'virtual-sports' }">
                        <span><b>virtual Sports</b></span>
                    </router-link>
                </li>

                <li class="nav-item">
                    <router-link :to="{name: 'Matka'}" class="cum-list" :class="{active : routeName == 'Matka' }">
                        <span><b>matka</b></span>
                    </router-link>
                </li>

                <li class="nav-item" v-for="(sport, sIndex) in custom_sports" :key="sIndex">
                    <router-link :to="{name: 'Sports', params: {type: sport.slug, id: sport.id}}" class="cum-list" :class="{active : routeName == 'Sports' && $route.params.type == sport.slug }">
                        <b>{{ sport.name }}</b>
                    </router-link>
                </li>

            </ul>
        </div>
    </section>
</template>

<script>
import clickApi from '../../services/click-api';
import * as apiName from '../../services/urls';

export default {
    name: "SportSelection",
    data() {
        return {
            non_custom_sports: [],
            custom_sports: [],
            clickApiUrl: process.env.VUE_APP_CLICK_API + 'v1/aws-s3/download/',
        }
    },
    computed: {
        routeName() {
            return this.$route.name
        },
        sortedNonCustomSports() {
            const order = ['cricket', 'football', 'tennis'];
            return this.non_custom_sports.sort((a, b) => {
                const aIndex = order.indexOf(a.name.toLowerCase());
                const bIndex = order.indexOf(b.name.toLowerCase());

                if (aIndex !== -1 || bIndex !== -1) {
                return (aIndex === -1 ? Infinity : aIndex) - (bIndex === -1 ? Infinity : bIndex);
                }
                return a.rank - b.rank;
            });
        }
    },
    mounted() {
        this.get_sportsList();
    },
    methods: {
        get_sportsList() {
            this.loading = true;
            clickApi.get(apiName.GET_SPORTS).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        var all_sports = response.data.data
                        this.non_custom_sports = []
                        for (var i = 0; i < all_sports.length; i++) {
                            if (all_sports[i].is_custom == 0) {
                                if(all_sports[i].slug == 'soccer') {
                                    all_sports[i].name = 'football'
                                }
                                this.non_custom_sports.push(all_sports[i])
                            } else {
                                this.custom_sports.push(all_sports[i])
                            }
                        }
                        this.non_custom_sports.sort((a, b) => {
                            return a.rank - b.rank;
                        });

                        var sport_list = {}
                        sport_list['custom'] = this.custom_sports
                        sport_list['non_custom'] = this.sortedNonCustomSports;
                        this.$store.dispatch('setSportsList',sport_list);
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    console.log(error)
                    // this.showErrorModalFunc(error);
                }
            });
        },
    }

};
</script>


<style scoped>
.width-100 {
    width: 100% !important;
}
</style>