<template>
    <SideMenu/>
    <section class="rules-card px-0" loading="lazy">
        <div class="rules-card-header">
            <h1>{{translatedLangData('rules', 'Rules')}}</h1>
        </div>
        <div class="container-fluid px-2">
            <div class="rules-card-body">
                <div>
                    <template v-for="(rule, r_index, rule_num) in siteRuleData" :key="r_index">
                        <template v-if="r_index == 'General'">                            
                            <div class="menu-details-list all-rule" v-html="rule"></div>
                        </template>
                    </template>
                </div>
            </div>
        </div>
    </section>
    <!-- Back to top button -->
    <div class="scroll-move-to-top-btn">
        <div class="contentDiv" id="content">
            <button class="back-to-top" :class="{show: scrollHeight}" type="button" @click="scrollToTop()"></button>
        </div>
    </div>
</template>

<script>
import SideMenu from '@/shared/components/header/SideMenu.vue';
import api from '../../services/api';
import * as apiName from '../../services/urls';
import { AUTH_TOKEN } from "@/shared/constants/cookies-const";
export default {
    name: 'Rules',
    inject: ['translatedLangData'],
    data() {
        return{
            siteRuleData: null,
            loading:false,
            scrollHeight: window.scrollY,
        }
    },
    mounted(){
        this.getRules();
        window.addEventListener('scroll', this.handleScroll);
    },
    methods:{
        getRules() {
            this.loading = true;
            this.$store.dispatch('setGlobalLoader', true);
            if(this.siteRuleData) {
                return;
            }
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.get(apiName.GET_SITE_RULES, { headers }).then(response => {
                this.loading = false;
                this.$store.dispatch('setGlobalLoader', false);
                // this.$store.dispatch('setGlobalLoader', false);
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                        }
                        else {
                            let ruleResp = response.data.data;
                            this.siteRuleData = {}
                            ruleResp.forEach(item => {
                                this.siteRuleData[item.type] = item.content;
                            })                            
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                this.$store.dispatch('setGlobalLoader', false);
                if (error) {
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },
        handleScroll() {
            this.scrollHeight = window.scrollY > 200;
        },
    }
}
</script>